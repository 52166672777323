export const soofiaLightTheme = {
  /* background: "#FFFFFF",
  primary: "#8453C9",
  secondary: "#f7f7f7",
  grey: "#e9e9e9",
  dark: "#000000",
  darken1: "#1c1c1c",
  darken2: "#2b2b2b",
  darken3: "#c0c0c0",
  darken4: "#5c5c5c",
  error: "#c37465",
  info: "#468bd1",
  success: "#46d147",
  warning: "#d16c46",
  "purple-variant": "#e7d6ff",
  "on-error": "#FFFFFF", // Color del texto en elementos secundarios
  "on-success": "#FFFFFF", // Color del texto en elementos secundarios
  "on-info": "#FFFFFF", // Color del texto en elementos secundarios
  "on-warning": "#FFFFFF", */ // Color del texto en elementos secundarios
  background: "#FFFFFF",
  onback: "#000000",
  surface: "#e9e9e9",
  secondary: "#f7f7f7",
  primary: "#8453C9",
  red: "#c37465",
  error: "#c37465",
  info: "#468bd1",
  success: "#46d147",
  warning: "#d16c46",
  purple: "#8453C9",
  "purple-variant": "#e7d6ff",
  "on-error": "#FFFFFF", // Color del texto en elementos secundarios
  "on-success": "#FFFFFF", // Color del texto en elementos secundarios
  "on-info": "#FFFFFF", // Color del texto en elementos secundarios
  "on-warning": "#FFFFFF", // Color del texto en elementos secundarios
};

export const soofiaDarkTheme = {
  background: "#000000",
  onback: "#FFFFFF",
  surface: "#1c1c1c",
  primary: "#f7f7f7",
  secondary: "#1c1c1c",
  red: "#c37465",
  error: "#c37465",
  info: "#468bd1",
  success: "#46d147",
  warning: "#d16c46",
  purple: "#8453C9",
  "purple-variant": "#e7d6ff",
  // Colores de texto
  "on-background": "#FFFFFF",
  "on-surface": "#FFFFFF",
  "on-primary": "#FFFFFF", // Color del texto en elementos primarios
  "on-secondary": "#FFFFFF", // Color del texto en elementos secundarios
  "on-error": "#FFFFFF", // Color del texto en elementos secundarios
  "on-success": "#FFFFFF", // Color del texto en elementos secundarios
  "on-info": "#FFFFFF", // Color del texto en elementos secundarios
  "on-warning": "#FFFFFF", // Color del texto en elementos secundarios
};

export const defaultTheme = {
  /* background: "#FFFFFF",
  primary: "#1976d2",
  secondary: "#f7f7f7",
  grey: "#e9e9e9",
  dark: "#000000",
  darken1: "#1c1c1c",
  darken2: "#2b2b2b",
  darken3: "#c0c0c0",
  darken4: "#5c5c5c",
  error: "#f44336",
  info: "#468bd1",
  success: "#46d147",
  warning: "#d16c46",
  "purple-variant": "#e7d6ff",
  "on-error": "#FFFFFF", // Color del texto en elementos secundarios
  "on-success": "#FFFFFF", // Color del texto en elementos secundarios
  "on-info": "#FFFFFF", // Color del texto en elementos secundarios
  "on-warning": "#FFFFFF", */ // Color del texto en elementos secundarios
  background: "#000000",
  onback: "#FFFFFF",
  surface: "#1c1c1c",
  primary: "#1976d2",
  secondary: "#f7f7f7",
  red: "#f44336",
  error: "#f44336",
  info: "#468bd1",
  success: "#4caf50",
  warning: "#d16c46",
  purple: "#8453C9",
  "purple-variant": "#e7d6ff",
  // Colores de texto
  "on-background": "#FFFFFF",
  "on-surface": "#FFFFFF",
  "on-primary": "#FFFFFF", // Color del texto en elementos primarios
  "on-secondary": "#FFFFFF", // Color del texto en elementos secundarios
  "on-error": "#FFFFFF", // Color del texto en elementos secundarios
  "on-success": "#FFFFFF", // Color del texto en elementos secundarios
  "on-info": "#FFFFFF", // Color del texto en elementos secundarios
  "on-warning": "#FFFFFF", // Color del texto en elementos secundarios
};
