<template>
  <v-card height="100%" class="mx-auto card-nav">
    <v-navigation-drawer permanent width="270">
      <template v-slot:prepend>
        <v-list-item two-line>
          <v-list-item-avatar>
            <img :src="logo">
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title>{{ empresa }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </template>

      <v-list nav dense>
        <div v-for="( item, index ) in itemsMenu" :key="'mn' + index">
          <!-- no tiene submenu -->
          <v-tooltip bottom v-if="item.submenus.length == 0" color="primary">
            <template v-slot:activator="{ on, attrs }">
              <v-list-item color="primary" exact router :to="{ path: '/' }" v-bind="attrs" v-on="on">
                <v-list-item-action class="pa-0">
                  <v-icon color="primary">{{ item.icon }}</v-icon>
                </v-list-item-action>

                <v-list-item-content class="dark--text">
                  <v-list-item-title style="font-size: 13px">{{ item.title }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </template>
            <span>{{ item.title }}</span>
          </v-tooltip>

          <!-- tiene submenu  -->
          <div v-else>
            <v-list-group v-model="item.active" color="primary">
              <template v-slot:activator>
                <v-tooltip bottom color="primary">
                  <template v-slot:activator="{ on, attrs }">
                    <v-list-item-action class="pa-0" v-bind="attrs" v-on="on">
                        <v-icon color="primary">{{item.icon}}</v-icon>
                    </v-list-item-action>
                    <v-list-item-content v-bind="attrs" v-on="on">
                      <v-list-item-title style="font-size: 13px" v-text="item.title"></v-list-item-title>
                    </v-list-item-content>
                  </template>
                  <span>{{item.title}}</span>
                </v-tooltip>
              </template>

              <v-list-item
                v-for="( option, index ) in item.submenus"
                :key="'smn'+index"
                router exact :to="{name: option.to}"
              >
                <v-tooltip right bottom color="primary">
                  <template v-slot:activator="{ on, attrs }">
                    <v-list-item-action class="ml-3" v-bind="attrs" v-on="on">
                      <!-- <v-icon x-small >{{option.icon}}</v-icon> -->
                      <v-icon x-small >mdi-circle-medium</v-icon>
                    </v-list-item-action>
                    <v-list-item-content class="darken4--text" v-bind="attrs" v-on="on">
                      <v-list-item-title  v-text="option.title"></v-list-item-title>
                    </v-list-item-content>
                  </template>
                  <span>{{option.title}}</span>
                </v-tooltip>
              </v-list-item>

            </v-list-group>
          </div>
        </div>
      </v-list>
    </v-navigation-drawer>
  </v-card>
</template>

<script>
export default {
  data() {
    return {
      // logo: require('@/assets/logo_yo.png'),
      logo: 'https://yo.geopop.org/' + this.$store.state.user.Emp_Imagen,
      empresa: this.$store.state.user.Emp_Nombre,
      right: null,
      itemsMenu: [],
      /* itemsMenu: [
        { title: 'Dashboard', icon: 'mdi-home', to: '/', submenus: [] },
        { title: 'Comercios Adheridos', icon: 'mdi-store-marker', to: '/', submenus: [
          { title: 'Comercios', icon: 'mdi-cog', to: {name: 'GestionComercio'}, submenus: [] },
          { title: 'Gestión de Sucursales', icon: 'mdi-circle-medium', to: {name: 'GestionSucursales'}, submenus: [] },
        ] },

        { title: 'Productos', icon: 'mdi-package-variant-closed', to: '/', 
        submenus: [
          { title: 'Gestión de Productos', icon: 'mdi-circle-medium', to: {name: 'GestionProductos'}, submenus: [] },
          { title: 'Categoría de Productos', icon: 'mdi-cog', to: {name: 'GestionCategoria'}, submenus: [] },  
          ] 
        }, 
        
        { title: 'Beneficios', icon: 'mdi-star-box', to: '/', submenus: [
          { title: 'Gestión de Beneficios', icon: 'mdi-circle-medium', to: {name: 'GestionBeneficios'}, submenus: [] },
        ] },

        { title: 'Stock de Beneficios', icon: 'mdi-numeric-9-plus-box-multiple-outline', to: '/', submenus: [
          { title: 'Gestión de Stock', icon: 'mdi-circle-medium', to: {name: 'GestionStock'}, submenus: [] },
          { title: 'Consulta Mov. de Stock', icon: 'mdi-circle-medium', to: {name: 'ConsultaStock'}, submenus: [] },
          { title: 'Consulta Códigos de Canje', icon: 'mdi-circle-medium', to: {name: 'GestionCanje'}, submenus: [] },
         
        ] },

        { title: 'Canje por Beneficios', icon: 'mdi-swap-horizontal', to: '/', submenus: [
          { title: 'Consulta Mov. de Canjes', icon: 'mdi-circle-medium', to: {name: 'ConsultaCanje'}, submenus: [] },
          { title: 'Pendientes de Revisión', icon: 'mdi-circle-medium', to: {name: 'Pendientes'}, submenus: [] }, 
          
        ] },

        { title: 'Cuenta Corriente de Puntos', icon: 'mdi-counter', to: '/', submenus: [
          { title: 'Gestión de Puntos', icon: 'mdi-circle-medium', to: {name: 'GestionPuntos'}, submenus: [] },
          { title: 'Consulta Mov. de Puntos', icon: 'mdi-circle-medium', to: {name: 'ConsultaPuntos'}, submenus: [] },
          { title: 'Puntos a Vencer', icon: 'mdi-circle-medium', to: {name: 'VencimientoPuntos'}, submenus: [] },
        ] },
        
        { title: 'Reportes', icon: 'mdi-text-box-plus-outline', to: '/', submenus: [
          { title: 'Log de Operaciones', icon: 'mdi-circle-medium', to: {name: 'Operaciones'}, submenus: [] },
        ] },

      ] */
    }
  },
  beforeMount() {
    this.itemsMenu = this.$store.state.user.menuAdminBeneficios;
  }
}
</script>

<style>
.card-nav .mdi-chevron-down::before {
  font-size: 15px;
  /* color: rgba(0,0,0,.87); */
}

.v-application--is-ltr .v-list-group--no-action.v-list-group--sub-group>.v-list-group__items>.v-list-item {
  padding-left: 35px !important;
}

.v-navigation-drawer__border {
  width: 0px !important;
}

</style>
